const reportFullstory = (eventName, eventData = {}) => {
  try {
    if (window.FS && window.FS.event) {
      window.FS.event(eventName, eventData);
    }
  } catch (e) {
    console.log('failed to send FS event:', e);
  }
};

export default reportFullstory;
