import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookie from 'js-cookie';
import history from '@guestyci/history';

import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { blue700 } from '@guestyci/foundation/theme/colors';
import useTranslations from '@guestyci/localize/useTranslations';

import { isEmpty } from '@guestyci/foundation/utils';

import { utils, okta } from 'utils';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import {
  FIELDS_ID,
  addSubTitleText,
  addedSubTitleForSMSAuthScreen,
  getFormFieldBlock,
  toggleElement,
  changeMFAButtonsText,
  handleClickContinueButton,
  createErrorTipBlock,
  toggleEmailAndPasswordField,
  removeElement,
  addEyeIconForPasswordField,
  getNode,
  organizeLoginButtonPositions,
  reportErrorToDataDog,
  updateTitle,
} from './helpers';

import './Login.css';

import PasswordValidation from '../components/PasswordValidation';
import { handleLogout } from '../logout/helper';
const { dimension } = utils.getEnv();

const handleSuccess = ({ token, url }) => {
  utils.localStorage.setToken(token);
  utils.localStorage.setBiData(token);
  window.location.replace(url);
};

const translationKeyPrefix = 'auth_page:login.login';

const translations = utils.translations.getTranslations({
  keyPrefix: translationKeyPrefix,
  defaults: [
    ['errorTipMessage', 'The email or password is incorrect. Please try again'],
    [
      'smsSubTitleScreenMessage',
      'We will use the phone number you enter below to send you a verification code via SMS.',
    ],
    ['emailVerificationCode', 'Get an email with a verification code.'],
    ['setupEmail', 'Set up Email Authentication'],
    ['smsVerificationCode', 'Get an SMS with a verification code.'],
    ['setupSMS', 'Set up SMS Authentication'],
    [
      'setupGoogleAuthVerificationCode',
      'Use the Google Authenticator app to get a verification code.',
    ],
    ['setupGoogleAuth', 'Set up Google Authenticator'],
    ['subTitleText', 'Please enter your details to sign in.'],
  ],
});

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const [newPassword, setNewPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const {
    return_to: zendeskRedirectUrl,
    ref: urlRef,
    activation: isActivationFlow,
    gl: googleLoginParam,
  } = history.getQueryParams();

  const isGoogleLoginEnabled = useMemo(() => {
    return (
      Cookie.get('_guesty_google_login') === 'true' ||
      googleLoginParam === 'true' ||
      googleLoginParam === '1'
    );
  }, [googleLoginParam]);

  const [
    errorTipMessage,
    smsSubTitleScreenMessage,
    emailVerificationCode,
    setupEmail,
    smsVerificationCode,
    setupSMS,
    setupGoogleAuthVerificationCode,
    setupGoogleAuth,
    subTitleText,
  ] = useTranslations(translations);

  useSyncSessionStorage('zendeskRedirectUrl', zendeskRedirectUrl);
  useSyncSessionStorage('ref', urlRef);
  useSyncSessionStorage('isActivationFlow', isActivationFlow);

  utils.useDocumentTitle('Login');
  utils.usePageView('login');

  // Prevent ENTER key press, when the password field is hidden
  useEffect(() => {
    const handleEnterPress = e => {
      const emailField = document.getElementById(FIELDS_ID.email);
      const passwordField = document.getElementById(FIELDS_ID.password);

      const passwordFiledBlock = getFormFieldBlock('password');

      if (e.keyCode === 13) {
        if (passwordFiledBlock && passwordFiledBlock.style.display === 'none') {
          e.preventDefault();
        }

        if (
          passwordField &&
          passwordField.value === '' &&
          passwordFiledBlock.style.display === 'block'
        ) {
          toggleElement('.okta-form-subtitle', false);
        }

        if (
          emailField &&
          emailField.value !== '' &&
          passwordFiledBlock.style.display === 'none'
        ) {
          handleClickContinueButton();
          return false;
        }
      }

      return true;
    };

    window.addEventListener('keypress', handleEnterPress);

    return () => {
      window.removeEventListener('keypress', handleEnterPress);
    };
  }, []);

  useEffect(() => {
    const { s: mfaSecret } = history.getQueryParams();
    if (mfaSecret) {
      utils.api
        .confirmMfa(mfaSecret)
        .then(response => {
          const token = response?.data?.token;
          if (token) {
            handleSuccess({ token, url: '/' });
          }
        })
        .catch(() => {});
    }
  }, []);

  const insertPasswordChecklist = () => {
    const mainInput = getNode('[name="newPassword"]');
    const submitBtn = getNode('.button[type=submit]');
    const confirmInput = getNode('[name="confirmPassword"]');
    const confirmField = getNode('.o-form-fieldset:nth-child(2)');
    const validationsContainer = getNode('.password-validations-container');

    confirmField.after(validationsContainer); // insert password checklist component
    toggleElement('.okta-form-subtitle', false); // hide description
    toggleElement('.o-form-fieldset:nth-child(2)', false); // hide confirmation field
    toggleElement('.password-validations-container', true); // show password validation component

    mainInput.addEventListener('input', event => {
      setShowWarning(false);
      // sync password fields
      const value = event.target.value;

      setNewPassword(value);
      confirmInput.value = value;
      confirmInput.dispatchEvent(new Event('change', { bubbles: true })); // trigger Okta validation
    });

    submitBtn?.addEventListener('click', event => {
      const password = mainInput.value;

      if (!utils.validators.isPasswordValid(password)) {
        event.preventDefault();
        setShowWarning(true);
      }
    });
  };

  const addRegistrationBlockForGuestyHostUsers = () => {
    const registrationBlock = getNode('.registration-container');
    const loginBlockGFH = getNode('.guesty-for-host-login');

    if (!loginBlockGFH && registrationBlock) {
      const contentContainer = document.createElement('div');
      contentContainer.classList.add('content-container');
      contentContainer.classList.add('guesty-for-host-login');

      const registrationLabel = document.createElement('span');
      registrationLabel.textContent = 'Guesty For Hosts User? ';
      registrationLabel.classList.add('registration-label');

      const registrationLink = document.createElement('a');
      registrationLink.textContent = 'Login here';
      registrationLink.href = 'https://app.guestyforhosts.com/';

      contentContainer.appendChild(registrationLabel);
      contentContainer.appendChild(registrationLink);

      registrationBlock.prepend(contentContainer);
    }
  };

  const addRegistrationBlock = () => {
    const registrationBlock = getNode('.registration-container');

    if (utils.isHQDomain && registrationBlock) {
      registrationBlock.remove();

      return;
    }

    addRegistrationBlockForGuestyHostUsers();
  };

  const registerWidgetEvents = widget => {
    widget.on('afterError', (_context, error) => {
      console.log('After error called', { error });
      toggleElement('.auth-content', true);

      if (!isEmpty(error)) {
        reportErrorToDataDog(error, {
          errorType: 'okta_widget_error',
          errorLocation: 'Login',
          context: _context,
        });

        toggleElement('.okta-form-subtitle', false);

        if (error?.statusCode === 401) {
          createErrorTipBlock(errorTipMessage);
        }
      }
    });

    widget.after('identify', async () => {
      console.log('Widget identify called');
    });

    widget.on('afterRender', context => {
      console.log('Rendered');
      console.log('After render called', { context });

      const handleOnGoogleButtonClick = () => {
        utils.reportFullStory('authentication:google_login_clicked');
      };

      if (isGoogleLoginEnabled) {
        organizeLoginButtonPositions(handleOnGoogleButtonClick);
      } else {
        const el = document.querySelector('#okta-sign-in .auth-divider');
        if (el) {
          el.remove?.();
        }
      }

      addRegistrationBlock();

      addedSubTitleForSMSAuthScreen({ smsSubTitleScreenMessage, setupSMS });
      changeMFAButtonsText([
        {
          tag: 'p',
          text: emailVerificationCode,
          buttonText: setupEmail,
        },
        {
          tag: 'p',
          text: smsVerificationCode,
          buttonText: setupSMS,
        },
        {
          tag: 'p',
          text: setupGoogleAuthVerificationCode,
          buttonText: setupGoogleAuth,
        },
      ]);

      if (context?.controller === 'password-reset') {
        insertPasswordChecklist();
        addEyeIconForPasswordField('[name="newPassword"]');
      }
      if (context?.controller === 'forgot-password') {
        updateTitle('Forgot password?');
      }

      // hide form while loading because of content replacement
      toggleElement(
        '.auth-content',
        context?.controller !== 'recovery-loading'
      );

      if (context?.controller !== 'primary-auth') {
        return;
      }

      toggleEmailAndPasswordField('password', false);
      toggleElement('.auth-footer', false);
      addSubTitleText(subTitleText);

      addEyeIconForPasswordField('#okta-signin-password');

      const errorBlock = getNode('.o-form-error-container');
      const title = getNode('.okta-form-title.o-form-head');

      title.after(errorBlock);

      const submitForm = getNode('#form1');
      submitForm?.addEventListener('submit', () => {
        exitErrorState();
      });
    });
  };

  const processCreds = async (creds, callback) => {
    utils.eventsLogger.unifiedLoginDio.track('input_password', 'click');
    callback();
  };

  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widgetConfig = getWidgetConfig(processCreds, isGoogleLoginEnabled);
    const widget = new OktaSignIn(widgetConfig);
    registerWidgetEvents(widget);

    widget.showSignInAndRedirect({
      el: widgetRef.current,
    });

    return () => widget.remove();
  }, [oktaAuth]);

  useEffect(() => {
    const handleLoginWithActiveSession = async () => {
      const session = await oktaAuth.session.get();
      if (session.status === 'ACTIVE') {
        handleLogout(oktaAuth);
      }
    };
    handleLoginWithActiveSession();
  }, [oktaAuth]);

  return (
    <div>
      <div ref={widgetRef} />
      <div
        className="password-validations-container"
        style={{ display: 'none' }}
      >
        <PasswordValidation showWarning={showWarning} password={newPassword} />
      </div>
    </div>
  );
};

export default Login;

function exitErrorState() {
  removeElement('.error-tip-block');
  removeElement('.error-auth-block');
  removeElement('#error-container');
}

function getUserLanguage() {
  const userLanguage = window.localStorage.getItem('lang');
  if (!userLanguage || userLanguage === 'en-US') {
    return 'en';
  }
  return userLanguage;
}

function sendLiteUserEventDataOnCreatePasswordFlow(userEmail) {
  if (utils.eventsLogger.unifiedLoginDio) {
    utils.eventsLogger.unifiedLoginDio.track('create_password', 'pageview', {
      userEmail,
    });
  }
}

function getWidgetConfig(processCreds, isGoogleLoginEnabled) {
  const {
    issuer,
    clientId,
    redirectUri,
    scopes,
    useInteractionCode,
    googleIDPId,
  } = okta.oidc;

  const userLanguage = getUserLanguage();
  const oktaLoginTranslations = utils.getOktaTranslations(translationKeyPrefix);
  const searchParams = new URLSearchParams(window.location.search);
  const recoveryToken = searchParams.get('token');
  const userEmail = searchParams.get('mailRef');

  if (recoveryToken) {
    if (userEmail) {
      sendLiteUserEventDataOnCreatePasswordFlow(userEmail);
    }

    window.history.replaceState({}, document.title, window.location.pathname);
  }

  return {
    baseUrl: issuer.split('/oauth2')[0],
    recoveryToken,
    clientId,
    redirectUri,
    logo: `${process.env.PUBLIC_URL}/guesty-logo-dark-blue.svg`,
    language: userLanguage,
    i18n: { [userLanguage]: oktaLoginTranslations },
    features: { rememberMe: true, registration: true },
    authParams: {
      issuer,
      scopes,
      prompt: 'consent',
    },
    processCreds,
    useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
    brandName: 'Guesty',
    colors: {
      brand: blue700,
    },
    customButtons: [
      {
        i18nKey: 'customButton.title',
        className: 'button button-primary btn-continue',
        click(e) {
          e.preventDefault();
          handleClickContinueButton();
        },
      },
    ],
    helpLinks: {
      forgotPassword: dimension === 'app' ? '/auth/forgot' : undefined,
      help:
        'https://help.guesty.com/hc/en-gb/articles/9369852308509-Troubleshooting-Login-Issues',
    },
    registration: {
      click() {
        window.location.href = 'https://www.guesty.com/request-a-demo/';
      },
    },
    idps:
      isGoogleLoginEnabled && googleIDPId
        ? [{ type: 'GOOGLE', id: googleIDPId }]
        : undefined,
    prompt: 'consent',
    idpDisplay: 'SECONDARY',
  };
}
/* RELATED LINKS
https://github.com/okta/okta-signin-widget/blob/master/docs/classic.md#openid-connect
https://developer.okta.com/docs/reference/api/oidc/#authorize
* */

function useSyncSessionStorage(key, value) {
  useEffect(() => {
    if (value) {
      window.sessionStorage.setItem(key, value);
    } else {
      window.sessionStorage.removeItem(key);
    }
  }, [key, value]);
}
