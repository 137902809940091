import { zendeskSsoLogin } from './api';

export const getZendeskRedirectUrl = async ({
  accessToken,
  zendeskRedirectUrl,
}) => {
  const response = await zendeskSsoLogin({
    token: accessToken.accessToken,
    redirectUrl: zendeskRedirectUrl,
  });
  return response?.data;
};

export default { getZendeskRedirectUrl };
