import Row from '@guestyci/foundation/Row';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(
  theme => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.secondary.default,
      height: 50,
    },
    label: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.white,
    },
  }),
  { name: 'InstanceBanner' }
);

const InstanceBanner = ({ testId = 'instance-banner' }) => {
  const classes = useStyles();

  const getTitle = () => {
    return 'Loading...';
  };

  const title = getTitle();

  return (
    <Row
      fullWidth
      spacing={4}
      justify="center"
      align="center"
      data-testid={testId}
      className={classes.root}
    >
      <TextField variant="h4" className={classes.label}>
        {title}
      </TextField>
    </Row>
  );
};

export default InstanceBanner;
