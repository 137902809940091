import React from 'react';
import createHistory from '@guestyci/history/createHistory';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import { Router } from 'react-router-dom';
import '@guestyci/foundation/style/styles.css';
import ToastProvider from '@guestyci/foundation/Toast';

import { utils } from 'utils';

import App from './app/App';

utils.eventsLogger.initLoggers();

const history = createHistory({ baseUrl: '/auth', isFragment: false });

const AppProvider = () => {
  return (
    <Router history={history}>
      <LocalizationProvider>
        <ToastProvider>
          <App />
        </ToastProvider>
      </LocalizationProvider>
    </Router>
  );
};

export { AppProvider };
