// TODO: We should updated this config

export default {
  hq: {
    clientId: '0oaei6duebeXaRX1n5d7',
    issuer: 'https://login.guesty.com/oauth2/ausei6sliu1aPxkL25d7',
  },
  'hq-trial': {
    clientId: '0oaei6duebeXaRX1n5d7',
    issuer: 'https://login.guesty.com/oauth2/ausei6sliu1aPxkL25d7',
  },
  'hq-staging0': {
    clientId: '0oa5uurgz2gTtijhN5d7',
    issuer: 'https://login-staging0.guesty.com/oauth2/aus5uux0nrEFk69cb5d7',
  },
  'hq-staging1': {
    clientId: '0oae0z9lumj0aMAfZ5d7',
    issuer: 'https://login-staging1.guesty.com/oauth2/ause0z9lsxX35SWQ45d7',
  },
  'hq-staging2': {
    clientId: '0oaerkzylrGR8xyKA5d7',
    issuer: 'https://login-staging2.guesty.com/oauth2/ausdnn4u2bUtFUnNg5d7',
  },
  'hq-staging3': {
    clientId: '0oaerl7v83XBO2Dld5d7',
    issuer: 'https://login-staging3.guesty.com/oauth2/ausej55yndpZmg2ro5d7',
  },
  'hq-staging4': {
    clientId: '0oae0yrlk0Ia9OhN25d7',
    issuer: 'https://login-staging4.guesty.com/oauth2/ause0yqnx0V0qrJpN5d7',
  },
  'hq-staging5': {
    clientId: '0oaer1g441JINS1qi5d7',
    issuer: 'https://login-staging5.guesty.com/oauth2/auser1hqh5IA2WTjS5d7',
  },
  'hq-staging6': {
    clientId: '0oaes9s62gJsx5OPI5d7',
    issuer: 'https://login-staging6.guesty.com/oauth2/ause1ii0pxMuhzkqX5d7',
  },
  'hq-staging7': {
    clientId: '0oaerkepclTaBC8Fi5d7',
    issuer: 'https://login-staging7.guesty.com/oauth2/ausdnmvpfbESpFfOt5d7',
  },
  'hq-staging8': {
    clientId: '0oaes9y5ymh2c9HRg5d7',
    issuer: 'https://login-staging8.guesty.com/oauth2/ause1i2rbgOBpJpLn5d7',
  },
  'hq-staging9': {
    clientId: '0oae1i1p9o8tk2rm25d7',
    issuer: 'https://login-staging9.guesty.com/oauth2/ause1i0edfJvdWOnK5d7',
  },
  'hq-staging10': {
    clientId: '0oaesajh5g92P7p5S5d7',
    issuer: 'https://login-staging10.guesty.com/oauth2/ause1hyh19t355mdX5d7',
  },
  'hq-staging11': {
    clientId: '0oaes9nmkbsuB0coK5d7',
    issuer: 'https://login-staging11.guesty.com/oauth2/ause15y3yoFu7v5LQ5d7',
  },
  'hq-staging12': {
    clientId: '0oae0ymxnzDRyTf2m5d7',
    issuer: 'https://login-staging12.guesty.com/oauth2/ause0ynwv3DVgpBIn5d7',
  },
  'hq-staging13': {
    clientId: '0oae1nh66f09x4mrO5d7',
    issuer: 'https://login-staging13.guesty.com/oauth2/ause1njnw4OeFB2qb5d7',
  },
  'hq-staging14': {
    clientId: '0oaesbtn8mnRbyBbd5d7',
    issuer: 'https://login-staging14.guesty.com/oauth2/ause1i3aimYmHj3rY5d7',
  },
  'hq-staging15': {
    clientId: '0oae15r1w68RABGO15d7',
    issuer: 'https://login-staging15.guesty.com/oauth2/ause15nduwv9NUhZl5d7',
  },
  'hq-staging16': {
    clientId: '0oaesclzgrnEUdvZs5d7',
    issuer: 'https://login-staging16.guesty.com/oauth2/ausescj2qdi6GVEEO5d7',
  },
  'hq-staging17': {
    clientId: '0oaescy5k2CSimT9M5d7',
    issuer: 'https://login-staging17.guesty.com/oauth2/ause1hseulWOhO9me5d7',
  },
  'hq-staging18': {
    clientId: '0oae0zmrq8ARWPWTd5d7',
    issuer: 'https://login-staging18.guesty.com/oauth2/ause0zm97kAQWbCcH5d7',
  },
  'hq-staging19': {
    clientId: '0oae15m9a7ZfhIhjp5d7',
    issuer: 'https://login-staging19.guesty.com/oauth2/ause15j84g9BYSpei5d7',
  },
  'hq-staging20': {
    clientId: '0oae1if6skYsgAe8s5d7',
    issuer: 'https://login-staging20.guesty.com/oauth2/ause1ie7g8h2R2HWa5d7',
  },
  'hq-sandbox': {
    clientId: '0oaa46egp0ptn5Ad1697',
    issuer: 'https://login-sandbox.guesty.com/oauth2/ausa46kq3mcCDQGi8697',
  },
};
