import React from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import Col from '@guestyci/foundation/next/Layout/Col';

import Logo from '../Logo';

export const useStyles = createStyles(
  {
    contentWrapper: { marginTop: 50 },
    wrapper: {
      width: '100%',
      maxWidth: 316,
      minHeight: 610,
      paddingTop: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 10px',
    },
  },
  { name: 'Layout' }
);

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Logo />
        <Col
          className={classes.contentWrapper}
          spacing={4}
          fullWidth
          align="center"
          justify="center"
        >
          {children}
        </Col>
      </div>
    </div>
  );
};
export default Layout;
