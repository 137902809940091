const getTranslations = ({ defaults, keyPrefix }) =>
  defaults.map(([stringId, d]) => ({
    id: `${keyPrefix}.${stringId}`,
    d,
  }));

const getDynamicTranslations = ({ defaults, keyPrefix }) =>
  defaults.map(([stringId, d, variables]) => ({
    id: `${keyPrefix}.${stringId}`,
    d,
    variables,
  }));

export { getTranslations, getDynamicTranslations };
