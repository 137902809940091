import Resource from '@guestyci/agni';
import { getEnv } from './env';

const { env } = getEnv();
const apiKey =
  env === 'production'
    ? 'S0Lco886orA64UonyXOmsyFP7j06nln4'
    : 'T26830axRZk77paW4p7u22ypRUhZ7F3m';

const mailerUrl =
  env === 'production'
    ? 'https://dashboard-mailer.production.guesty.com/api/v2'
    : `https://app-${env}.guesty.com/api/v2`;

const { api: mailerApi } = Resource.create({ env, withCredentials: true });
const { api: zendeskIntegration } = Resource.create();
const { api: loginApi } = Resource.create({ env, withCredentials: true });
// by default - 401 responses are redirected to auth/logout - not needed for the login flow
loginApi.interceptors.response.eject(0);

export const login = ({ username, password }) => {
  return loginApi.post(`${mailerUrl}/authenticate`, {
    apiKey,
    username,
    password,
  });
};

export const isMigrated = ({ username }) => {
  return mailerApi.get('/authenticate/is-migrated', {
    params: { username },
  });
};

export const zendeskSsoLogin = async ({ token, redirectUrl }) => {
  const apiUrl =
    env === 'production'
      ? `https://app.guesty.com/api`
      : `https://app-${env}.guesty.com/api`;
  return zendeskIntegration.post(`${apiUrl}/zendesk-integration/sso-login`, {
    token,
    redirectUrl,
  });
};

export const resetPassword = username => {
  return mailerApi.post(`/authenticate/forgot-password`, {
    apiKey,
    username,
  });
};

export const savePassword = ({ password, hash }) => {
  return mailerApi.post(`/authenticate/forgot-password/reset`, {
    apiKey,
    hash,
    password,
  });
};

export const confirmMfa = secret => {
  return mailerApi.post(`${mailerUrl}/mfa/confirm`, { secret });
};

export const resendMfaMail = email => {
  return mailerApi.post(`${mailerUrl}/mfa/resend`, { email });
};

export const verifyReset = hash => {
  return mailerApi.post(`/authenticate/forgot-password/verify`, {
    hash,
    apiKey,
  });
};
