import { useEffect } from 'react';
import {
  forgotDio,
  resetDio,
  mfaDio,
  unifiedLoginDio,
  unifiedLogoutDio,
} from './eventsLogger';

const mapper = {
  login: { dio: unifiedLoginDio, eventName: 'view_login' },
  logout: { dio: unifiedLogoutDio, eventName: 'view_logout' },
  reset: { dio: resetDio, eventName: 'view_reset' },
  mfa: { dio: mfaDio, eventName: 'view_mfa' },
  forgot: { dio: forgotDio, eventName: 'view_forgot' },
};

const usePageView = (pageName, properties) => {
  useEffect(() => {
    const { dio, eventName } = mapper[pageName] || {};
    if (dio && Object.keys(dio) > 0) {
      dio.track(eventName, 'pageview', properties);
    }
  }, [pageName, properties]);
};
export default usePageView;
