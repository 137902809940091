/* eslint-disable camelcase */
import jwtDecode from 'jwt-decode';
import { getEnv } from './env';

// TODO: should we pass env and change structure for HQ domain ?
export default accessToken => {
  const tokenData = jwtDecode(accessToken);

  const isHq = 'hq' === getEnv().dimension;

  const hqNormalizedData = isHq
    ? {
        orgId: tokenData.orgId,
        hqUserId: tokenData.hqUserId,
      }
    : {};

  const normalizedData = {
    _id: tokenData.userId,
    fullName: tokenData.fullName,
    user_id: tokenData.userId,
    username: tokenData.fullName,
    email: tokenData.name,
    displayName: tokenData.fullName,
    accountId: tokenData.accountId,
    userRoles: tokenData.userRoles,
    requester: tokenData.requester,
    ...hqNormalizedData,
  };

  window.localStorage.setItem(
    'guesty_user_data',
    JSON.stringify(normalizedData)
  );
};
