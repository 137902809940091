import Dio from '@guestyci/dio';
import Resource from '@guestyci/agni';
import uuid from '@guestyci/dio/uuid';
import { getEnv } from './env';

const { env } = getEnv();

const { config } = Resource.create({ domain: '/', env });

const enrichments = {
  anonymousData: {
    uniqeAnonymousId: uuid(),
  },
};

export const unifiedLogoutDio = {};
const initUnifiedLogoutDio = () => {
  Object.assign(
    unifiedLogoutDio,
    Dio.init({
      context: 'login',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
    })
  );
};

export const unifiedLoginDio = {};
const initUnifiedLoginDio = () => {
  Object.assign(
    unifiedLoginDio,
    Dio.init({
      context: 'login',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
      enrichments,
    })
  );
};

export const loginDio = {};
const initLoginDio = () => {
  Object.assign(
    loginDio,
    Dio.init({
      context: 'auth/login',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
      enrichments,
    })
  );
};

export const forgotDio = {};
const initForgotDio = () => {
  Object.assign(
    forgotDio,
    Dio.init({
      context: 'auth/forgot',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
      enrichments,
    })
  );
};

export const resetDio = {};
const initResetDio = () => {
  Object.assign(
    resetDio,
    Dio.init({
      context: 'auth/reset',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
      enrichments,
    })
  );
};

export const mfaDio = {};
const initMfaDio = () => {
  Object.assign(
    mfaDio,
    Dio.init({
      context: 'auth/mfaAuthentication',
      baseUrl: config.API_GATEWAY,
      isPublic: true,
      enrichments,
    })
  );
};

export const initLoggers = () => {
  initLoginDio();
  initForgotDio();
  initMfaDio();
  initResetDio();
  initUnifiedLogoutDio();
  initUnifiedLoginDio();
};
