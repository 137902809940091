import { Suspense, useEffect, useState, lazy } from 'react';
import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';
import cookie from 'js-cookie';
import InstanceBanner from './InstanceBanner';
import { useOktaAuth } from '@okta/okta-react';
import { getSecureAuthCookie } from '../../login/helpers';
import Col from '@guestyci/foundation/Col';

import { utils } from 'utils';
import { getEnv } from 'utils/env';

const ErrorComponent = lazy(() => import('./SessionError'));

const useStyles = createStyles(
  () => ({
    root: {
      minHeight: '100vh',
      backgroundColor: 'white',
    },
    spinner: {
      minHeight: '100vh',
      maxWidth: '10vh',
      margin: 'auto',
    },
  }),
  { name: 'InstanceLogin' }
);

const SessionHandler = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const { oktaAuth: authClient } = useOktaAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get('redirectTo');

  useEffect(() => {
    const initAuthentication = async () => {
      try {
        const session = await authClient.session.get();
        if (session.status === 'INACTIVE') {
          console.log('Loading - Session inactive - Throwing');
          throw new Error('Session inactive');
        }
        window.localStorage.removeItem('token');
        const { tokens } = await authClient.token.getWithoutPrompt();
        console.log('Loading - Finished generating tokens.');
        authClient.tokenManager.setTokens(tokens);
        await getSecureAuthCookie();
        console.log('Loading - Finished saving tokens.');
        if (tokens?.accessToken) {
          utils.saveUserData(tokens.accessToken.accessToken);
        }

        cookie.remove('hq_inst_pdg', { domain: '.guesty.com' });
        const { dimension } = getEnv();
        if (dimension === 'app' && !tokens?.accessToken?.claims?.accountId) {
          throw new Error('missing account id');
        }

        if (redirectTo) {
          window.location.replace(redirectTo);
        } else {
          window.location.replace('/');
        }
      } catch (e) {
        console.error('Finish session handler with error - logout', e);
        setError(e);
      }
    };
    initAuthentication();
  }, [authClient, redirectTo]);

  if (error) {
    return (
      <Suspense fallback={null}>
        <ErrorComponent />;
      </Suspense>
    );
  }

  return (
    <Col className={classes.root}>
      <InstanceBanner />
      <Spinner className={classes.spinner} size="40" />
    </Col>
  );
};

export default SessionHandler;
