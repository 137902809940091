import React from 'react';
import cn from 'classnames';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/next/TextField';

export const useStyles = createStyles(
  {
    root: {
      height: 'auto',
      color: '#77838D',
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  { name: 'Subtitle' }
);

const Subtitle = ({ children, className, color = 'deafult' }) => {
  const classes = useStyles();
  return (
    <TextField
      variant="h4"
      align="center"
      color={color}
      className={cn(classes.root, className)}
    >
      {children}
    </TextField>
  );
};

export default Subtitle;
