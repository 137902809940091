import React from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/next/TextField';

export const useStyles = createStyles(
  {
    root: {
      marginBottom: 10,
    },
  },
  { name: 'Title' }
);

const Title = ({ children }) => {
  const classes = useStyles();
  return (
    <TextField variant="h2" align="center" className={classes.root}>
      {children}
    </TextField>
  );
};
export default Title;
