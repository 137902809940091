import hqConfig from './config/hq';
import appConfig from './config/app';
import { getEnv } from '../env';

const configForEnv = (env, dimension) => {
  if (dimension === 'hq') {
    return hqConfig[env];
  }

  if (dimension === 'app') {
    return appConfig[env];
  }

  console.log('failed to find Okta config');

  return {};
};

const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}/auth/login/callback`;
const USE_INTERACTION_CODE = false;

const { env, dimension } = getEnv();

const envConfig = configForEnv(env, dimension);

const config = {
  oidc: {
    ...envConfig,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
    ignoreLifetime: true,
    brandName: 'Guesty',
  },
  app: {
    basename: BASENAME,
  },
};

export default config;
