import React, { useState } from 'react';

import ForgotForm from './ForgotForm';
import ResetSent from './ResetSent';

import { utils } from 'utils';

const Forgot = () => {
  utils.useDocumentTitle('Forgot Password');
  utils.usePageView('forgot');

  const [isResetSent, setIsResetSent] = useState(false);
  const handleResetSent = () => setIsResetSent(true);
  return (
    <>
      {!isResetSent && <ForgotForm onResetSent={handleResetSent} />}
      {isResetSent && <ResetSent />}
    </>
  );
};
export default Forgot;
