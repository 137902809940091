import jwtDecode from 'jwt-decode';

export const setToken = token => {
  if (token) {
    window.localStorage.setItem('token', JSON.stringify(token));
  }
};

export const setBiData = token => {
  if (token) {
    const { userId, accountId, iss, name, fullName } = jwtDecode(token);
    window.localStorage.setItem(
      'bi_data',
      JSON.stringify({
        userId,
        accountId,
        iss,
        name,
        fullName,
      })
    );
  }
};

/**
 * searches for items in local storage
 * @param {Array<string|RegExp>} keyQueries - array of strings or regexps to search for
 * @returns {Array<{key,value}>} array of items in a format of {key, value}
 */
export const findItems = keyQueries => {
  try {
    const keys = Object.keys(window.localStorage);

    const matchedKeys = keys.filter(key => {
      return keyQueries.some(query => {
        if (typeof query === 'string') {
          return key === query;
        }
        if (query instanceof RegExp) {
          return query.test(key);
        }

        return false;
      });
    });

    const items = matchedKeys.map(key => {
      return {
        key,
        value: window.localStorage.getItem(key),
      };
    });

    return items;
  } catch (error) {
    return [];
  }
};

export const clearLocalStorage = window.localStorage.clear;
//
