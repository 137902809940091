export const passRegex = [
  {
    id: 'length',
    regex: /.{8,}/,
  },
  {
    id: 'number',
    regex: /\d/,
  },
  {
    id: 'lowercase',
    regex: /[a-z]/,
  },
  {
    id: 'uppercase',
    regex: /[A-Z]/,
  },
];

export const isPasswordValid = password =>
  passRegex.every(({ regex }) => regex.test(password));
