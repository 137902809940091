import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import history from '@guestyci/history';
import { useOktaAuth } from '@okta/okta-react';

import { utils } from 'utils';
import { deleteSecureAuthCookie } from '../login/helpers';
import { handleLogout } from './helper';

const persistedKeys = [
  'lang',
  /^smartviews-.*\.savereminder$/,
  'permanentStorage',
];

const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const returnTo = queryParams.get('return_to');
  const isZendeskAuthorization = !!queryParams.get('zendeskSso');
  const persistedUserData = utils.localStorage.findItems(persistedKeys);

  // TODO: userId
  utils.usePageView('logout');

  useEffect(() => {
    const logout = async () => {
      try {
        await deleteSecureAuthCookie();
        await handleLogout(oktaAuth);
        if (persistedUserData.length) {
          persistedUserData.forEach(item => {
            window.localStorage.setItem(item.key, item.value);
          });
        }

        if (isZendeskAuthorization) {
          window.location.replace(returnTo);
        } else {
          history.replace({
            pathname: 'login',
            search: window.location.search,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    logout();
  }, []);

  return null;
};

export default Logout;
