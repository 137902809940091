// eslint-disable-next-line import/prefer-default-export
export const getEnv = () => {
  const { host } = window.location;

  if (host === 'app.guesty.com' || host === 'app-trial.guesty.com') {
    return {
      env: 'production',
      dimension: 'app',
    };
  }

  if (host === 'hq.guesty.com' || host === 'hq-trial.guesty.com') {
    return {
      env: 'hq',
      dimension: 'hq',
    };
  }

  const match = host.match(/(app|hq)-(\w+)\.guesty\.com/);

  console.log('match', match);

  if (match) {
    const dimension = match[1];
    const env = match[2];

    return {
      dimension,
      // Need to ask Kostya why its like that instead of returning the plain env with the prefix 'hq-'
      env: dimension === 'hq' ? `${dimension}-${env}` : env, // staging5 | hq-staging5 in case if host is staging5.guesty.com or hq-staging5.guesty.com or
    };
  }

  return {
    env: process.env.REACT_APP_CLUSTER || process.env.CLUSTER || 'staging',
    dimension: 'app',
  };
};
