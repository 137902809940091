import React, { useEffect, useState } from 'react';
import './style.css';
import useTranslations from '@guestyci/localize/useTranslations';

const OktaError = ({ error }) => {
  const [errorText, googleErrorText] = useTranslations([
    {
      id: 'auth_page:layout.error.general',
      d: 'Oops. Something went wrong while logging in.',
    },
    {
      id: 'auth_page:layout.error.google',
      d:
        'This Google email address is not registered to Guesty. Please try to login with a different address.',
    },
  ]);
  console.error(error);

  return (
    <div>
      <div className="error-info-container">
        <p>{errorText}</p>
        {error?.message === 'OAuthError: User creation was disabled.' && (
          <p>{googleErrorText}</p>
        )}
        <h6>
          <a href="/auth/login">Back to login</a>
        </h6>
        <div className="error-link-divider" />
        <p>
          <a
            href="https://help.guesty.com/hc/en-gb/articles/9369852308509-Troubleshooting-Login-Issues"
            target="_blank"
            rel="noreferrer noopener"
          >
            Help Center
          </a>
        </p>
      </div>
    </div>
  );
};

export default function LoginCallback({
  oktaAuth,
  authState,
  loadingElement = null,
  onAuthResume,
  errorComponent,
}) {
  const [callbackError, setCallbackError] = useState(null);

  const ErrorReporter = errorComponent || OktaError;

  const handelAuth = async () => {
    const isInteractionRequired =
      oktaAuth.idx.isInteractionRequired ||
      oktaAuth.isInteractionRequired.bind(oktaAuth);

    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    try {
      await oktaAuth.handleLoginRedirect();
    } catch (error) {
      setCallbackError(error);
    }
  };

  useEffect(() => {
    handelAuth();
  }, [oktaAuth]);

  const authError = authState?.error;
  const displayError = callbackError || authError;

  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
}
