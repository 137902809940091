const isRepUser = async authClient => {
  try {
    const accessToken = await authClient.tokenManager.get('accessToken');
    return !!accessToken?.claims?.representativeOf;
  } catch (e) {
    console.log('Cant find accessToken', e);
    return false;
  }
};

const clearDataDogUserSession = () => {
  if (window.DD_RUM) {
    window.DD_RUM.onReady(() => {
      window.DD_RUM.clearUser();
    });
  }
};

export const handleLogout = async authClient => {
  const isFromZendesk = Boolean(window.sessionStorage.getItem('zendeskRedirectUrl'));
  const isUserRep = await isRepUser(authClient);
  const shouldCloseSession = !isUserRep;
  try {
    if (shouldCloseSession) {
      await authClient.revokeAccessToken();
      await authClient.closeSession();
    }
    clearDataDogUserSession();
  } catch (e) {
    if (e.xhr && e.xhr.status === 429) {
      // Too many requests
      console.log('Too many requests');
    }
  }
  if (shouldCloseSession) {
    window.localStorage.clear();
  }
  if (!isFromZendesk) {
    window.sessionStorage.clear();
  }
};
