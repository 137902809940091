import { intl } from '@guestyci/localize';
// labels: https://github.com/okta/okta-signin-widget/blob/master/packages/%40okta/i18n/src/properties/login.properties
const defaultTexts = {
  'customButton.title': 'Continue',
  'primaryauth.title': 'Welcome!',
  'error.username.required': 'Please enter an email',
  'primaryauth.username.placeholder': 'Email',
  'primaryauth.username.tooltip': 'Email',
  'registration.signup.label': 'New to Guesty?',
  'registration.signup.text': 'Register a new account',
  'password.forgot.emailSent.title': 'Check your email',
  'password.forgot.sendEmail': 'Reset password',
  'password.forgot.email.or.username.placeholder':
    'We’ll email you a link you can use to reset your password securely.',
  'password.forgot.email.or.username.tooltip': ' Email',
  help: 'Need help?',
  'enroll.choices.title': 'Set up Multi-factor Authentication',
  'error.auth.lockedOut':
    'Too many failed attempts. Please try again in 10 minutes.',
  'errors.E0000004': 'Sorry, we do not recognize those details.',
  // MFA translations
  'mfa.backToFactors': 'Back to authentication setup',
  'enroll.choices.description.specific':
    '{0} requires an additional identification method to add a layer of security when signing in. Select an authentication option:',
  'enroll.sms.setup': 'Set up SMS Authentication',
  'email.enroll.title': 'Email Authentication',
  'email.button.send': 'Send code',
  'email.mfa.email.sent.description':
    'We sent a verification code to {0}. Enter the code below to verify your email.',
  'email.button.resend': 'Resend code',
  'email.code.not.received': "Didn't receive a code via email?",
  'email.code.label': 'Enter code',
  'factor.sms.description': 'Get an SMS with a verification code.',
  'factor.email.description': 'Get an email with a verification code.',
  'factor.totpSoft.description':
    'Use the Google Authenticator app to get a verification code.',
  'factor.sms.time.warning': "Didn't receive a code via SMS? Resend code",
  'password.reset.title.specific': 'Create your {0} password',
  'password.reset': 'Create password',
  // {0} is a generated list of requirements, based on translations from the below keys.
  'password.complexity.requirements': 'Password must be {0}.',
};

const getTranslation = (keyPrefix, textKey) => {
  const translationKey = `${keyPrefix}.${textKey}`;
  const text = defaultTexts[textKey];
  const translated = intl.get(translationKey).d(text);
  return translated;
};

const getOktaTranslations = keyPrefix => {
  const languageOktaKeys = Object.keys(defaultTexts);
  const translations = languageOktaKeys.reduce((acc, key) => {
    const translated = getTranslation(keyPrefix, key);
    return { ...acc, [key]: translated };
  }, {});
  return translations;
};

export default getOktaTranslations;
